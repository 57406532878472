<template>
  <div id="analyse-statistics-container">
    <button-router></button-router>

    <router-view v-slot="{ Component }">
      <!-- <keep-alive> -->
      <component :is="Component"></component>
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<style lang="scss" >
#analyse-statistics-container {
  height: 100%;
  .statement-container {
    padding: 20px 4px 20px 20px;
    box-sizing: border-box;
    .el-input,
    .el-select {
      width: 100%;
    }
    .border-icon {
      margin-left: 10px;
      display: inline-block;
      width: 32px;
      height: 32px;
      text-align: center;
      line-height: 32px;
      border-radius: 4px;
      cursor: pointer;
      background-color: var(--search-uncheck-bg-color);
      i {
        color: var(--text-third-color);
      }
      &:hover {
        background-color: var(--search-bg-color);
        i {
          color: var(--theme-color) !important;
        }
      }
    }
    .text-center {
      text-align: center;
      line-height: 40px;
      color: #ccc;
    }
    .mr-10 {
      margin-right: 10px;
    }
    .action-btn {
      text-align: right;
      .el-button {
        width: 70px;
        padding: 0;
      }
      .el-button--default {
        background-color: #fff;
        color: var(--theme-color);
        &:hover {
          background-color: var(--theme-color);
          color: #fff;
        }
      }
    }
    .content {
      padding: 20px 0 0 0;
    }
  }
  .analyse-table {
    .el-table th.el-table__cell {
      background-color: var(--search-bg-color);
      padding: 9px 0;
      font-size: 15px;
      color: var(--text-color);
    }
    .pagination-container {
      padding: 0;
      margin-top: 15px;
    }
    .statistics-container {
      background: #ffffff;
      padding: 20px 40px;
      display: flex;
      justify-content: flex-end;
      .statistics-item {
        display: flex;
        align-items: center;
        margin-right: 40px;
        .label {
          color: #999999;
        }
        .number {
          font-size: 20px;
        }
      }
    }
    .text-grey {
      color: var(--text-third-color);
    }
    .text-red {
      color: var(--error-color);
    }
    .text-theme {
      color: var(--theme-color);
    }
    .text-black {
      color: var(--text-color);
    }
    .text-orange {
      color: var(--warning-color);
    }
  }
  .analyse-echarts {
    .statistics-title {
      font-weight: bold;
      margin-bottom: 20px;
    }
    .top-data {
      margin-bottom: 50px;
      .basic-data {
        display: flex;
        justify-content: space-between;
        .basic-item {
          width: 15.8%;
          height: 100px;
          padding: 26px 16px;
          box-sizing: border-box;
          background: #ffffff;
          box-shadow: 2px 2px 20px 0px rgba(0, 114, 246, 0.06);
          border-radius: 5px;
          display: flex;
          align-items: center;
          img {
            width: 48px;
          }
          div {
            margin-left: 10px;
            span {
              font-weight: bold;
              color: #1a1a1a;
              font-size: 30px;
            }
            p {
              color: #999999;
            }
          }
        }
      }
    }
    .payway-data {
      .payway-flex {
        display: flex;
        justify-content: space-between;
        > div {
          padding: 20px;
          box-sizing: border-box;
          background-color: #fff;
          box-shadow: 2px 2px 20px 0px rgba(0, 114, 246, 0.06);
          border-radius: 5px;
        }
      }
    }
    .mt-50 {
      margin-top: 50px;
    }
  }
  .el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  .el-table--border::after,
  .el-table--group::after {
    width: 0;
  }
  .store-table-container {
    .el-table::before {
      height: 0;
    }
    .el-table--border .el-table__cell {
      border-right: transparent;
    }

    .summary-table {
      border: 1px solid transparent;
      .el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      .el-table--border::after,
      .el-table--group::after {
        width: 0;
      }
      .el-table__footer-wrapper,
      .el-table__body-wrapper,
      .el-table__header-wrapper {
        width: calc(100% - 17px) !important; // ( 100% - 横向滚动条高度 )
      }
      .is-group .el-table__cell {
        border-right: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
        text-align: center;
      }
      .el-table__footer-wrapper td.el-table__cell {
        border-top: transparent;
        font-weight: bolder;
      }

      tbody .el-table__cell {
        text-align: center;
      }
    }
  }
}
</style>
